import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { supabase } from "../helpers/supabase";
import { RingLoader } from "react-spinners";

import {
  loginContainerStyles,
  loginBoxStyles,
  loginFieldStyles,
} from "../styles/styles";
import "../styles/styles.scss";
import { FaEnvelope } from "react-icons/fa";
import Helmet from "react-helmet";

const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data) => {
    setSubmitting(true);
    supabase.auth.api.resetPasswordForEmail(data.email).then((response) => {
      setSubmitting(false);
      if (response.error) {
        alert(`Error sending email: ${response.error.message}`);
      } else {
        alert("Check your email to continue.");
      }
    });
  };

  return (
    <div class="section" style={loginContainerStyles}>
      <Helmet title="Forgot Password | Frontier Solutions" />
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <form
            class="box"
            style={loginBoxStyles}
            onSubmit={handleSubmit(onSubmit)}
          >
            <p class="title has-text-centered">Forgot your password?</p>
            <p class="is-size-7">
              Don't worry! It happens. Please enter the{" "}
              <strong>email address</strong> associated with your account.
            </p>
            <div class="field" style={loginFieldStyles}>
              {/* <label class="label">Code</label> */}
              <div class="control has-icons-left">
                <input
                  class="input"
                  {...register("email", { required: true })}
                />
                <span class="icon is-small is-left">
                  <FaEnvelope />
                </span>
              </div>
            </div>
            <div class="field" style={loginFieldStyles}>
              <button class="button is-fullwidth fpLoginButton" type="submit">
                {submitting ? <RingLoader size={30} /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
